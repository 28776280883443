'use client';

import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Ghost } from '@ghosts-inc/design-system/icons';
import { SvgIconProps } from '@mui/material';
import Spline from '@splinetool/react-spline';

/**
 * Checks if WebGL is available in the current browser.
 * WebGL is a JavaScript API for rendering interactive 2D and 3D graphics within any compatible web browser without the use of plug-ins.
 * This function ensures that the browser supports WebGL before attempting to use it.
 */
const isWebGLAvailable = (): boolean => {
  try {
    const canvas = document.createElement('canvas');
    return (
      !!window.WebGLRenderingContext &&
      !!(canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    );
  } catch (e) {
    return false;
  }
};

type Props = {
  svgSxProps?: SvgIconProps['sx'];
};

const Fallback = ({ svgSxProps }: Props) => (
  <Ghost sx={{ width: '60%', height: '60%', ...svgSxProps }} color="primary" />
);

export const GhostCoinSpline = ({ svgSxProps }: Props): JSX.Element => {
  const [isWebGLSupported, setIsWebGLSupported] = useState<boolean>();

  useEffect(() => {
    // this needs to run in a useEffect because the check MUST only run on the client
    // otherwise it will throw a hydration mismatch error
    setIsWebGLSupported(isWebGLAvailable());
  }, []);

  if (isWebGLSupported === undefined) {
    return <></>;
  }

  return isWebGLSupported ? (
    <ErrorBoundary fallback={<Fallback svgSxProps={svgSxProps} />}>
      <Spline scene="https://prod.spline.design/nFwrZ7QRrmAJ7qft/scene.splinecode" />
    </ErrorBoundary>
  ) : (
    <Fallback svgSxProps={svgSxProps} />
  );
};
